.image-box {
    background-color: #E7E9EB;
    height: 100px;
    width: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 10px;
}

.input-img {
    opacity: 0;
    position: absolute;
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 1000;
}

.bg-image {
    position: absolute;
}

.camera-svg {
    z-index: 999;
    cursor: pointer;
}

.z-index1004 {
    z-index: 1004;
}