@import '../bootstrap.scss';

.sidebar {
 height: 100%;
 width: 250px;
 background: $white;
 overflow-y: auto;
}

.sidebar .btn-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $medium;
}

.sidebar .btn-link:hover {
    background: $light !important;
}