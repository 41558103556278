@import '../bootstrap.scss'; 

#custom-filter {
    position: relative;
}

#custom-filter #filter-items {
    position: absolute;
    min-width: 100%;
    background: $light;
    z-index: 1000;
    visibility: hidden;
    max-height: 200px;
    overflow-y: auto;
}

#custom-filter #selected-filter {
    display: flex;
    align-items: center;
    min-width: 160px;
}