#value-offer-image .value-offer-img {
  width: 130px !important;
  height: 130px !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
  display: inline-block;
  background-image: url(../../icon/hurray.png);
  background-repeat:no-repeat;
  background-position:center center;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: 130%;
}

#value-offer-image .offer-amount-text {
  position: absolute;
  top: 32px;
  left: 28px;
}

#value-offer-image .offer-off-text {
  position: absolute;
  top: 78px;
  left: 45px;
}
