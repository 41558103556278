.other-address-type {
  border-bottom: 1px solid #ccc!important;
}

.product-image-div {
  width: 80px;
  max-width: 80px;
}

.product-image {
  max-width: 100%;
  max-height: 60px;
}

.w-20p {
  width: 20px;
}
