.productcart-showcase {
  max-height: 460px;
}

.product-show-container {
  max-height: 835px;
}

// .product-search-container {
//   height: 42px;
// }

.product-list-container {
 max-height: calc(100% - 42px);
 overflow: auto;
}

.image-container {
  height: 7rem;
}

.product-image {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.modal-dialog {
  height: 90%;
}

.modal-content {
  height: 100%;
  border-radius: 0 !important;
}

.modal-body {
  overflow: auto;
  padding: 0 !important;
}

.shoptag1 {
  width: 48px;
  padding: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  &:hover {
    width: 170px;
    .shoptag3 { 
      display: flex;
      align-items: center;
    };
  }
}

.shoptag2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px !important;
  height: 40px;
}

.shoptag3 {
  padding-left: 6px;
  color: white;
  height: calc(1.3rem * 2) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
}

.store-tile {
  width: 480px !important;
}

.store-list {
  overflow-y: scroll;
  max-height: 610px;
}

.image-upload-section {
  width: 10rem;
  height: 10rem;
  position: relative;
}

.profile-image-container  {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.profile-image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.image-upload-section .form-group {
 position: absolute;
 top: 75%;
 right: 10%;
}

.form-label {
  font-size: 0.8rem!important;
  color: #38383d;
}

.store-list {
  overflow-y: scroll;
  max-height: 610px;
}

.store-list::-webkit-scrollbar {
  width: 15px;
}

.store-list::-webkit-scrollbar-thumb {
  background: #c0c0c0;
}
