.review-width {
  width: 200px !important;
}

.sticker-image {
  height: 80px;
  width: 80px;
}

.retailer-story-form {
  background-color: #f4f4f4f0 !important;
}

.shop-image {
  width: 80% !important;
  object-fit: contain;
}

.retialer-image {
  width: 100% !important;
  max-width: 230px;
  object-fit: contain;
}

.video-container {
  width: 100% !important;
  max-width: 230px;
  object-fit: contain;
  height:100% !important;
  max-height: 120px;
}