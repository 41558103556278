.bg-lime {
  background-color: #BFFF00
}

.bg-orange {
  background-color: #FF8000;
}

.bg-green-yellow {
  background-color: #adff2f;
}

.bg-firebrick {
  background-color: #b22222;
}

.bg-olive {
  background-color: #808000;
}

.delivery-status {
  width: 140px;
  padding: 8px;
  border: 0;
}

.flex-1 {
  flex: 1;
}

.store-name-cover {
  max-width: 240px;
  min-width: 240px;
  display: flex;
}

.lightGreenInput {
  background-color: #d9ead3 !important;
  color: black !important;
  border-radius: 0;
}

.lightYellowInput {
  background-color: #fff2cc !important;
  color: black !important;
  border-radius: 0;
}

.lightRedInput {
  background-color: #f4cccc !important;
  color: black !important;
  border-radius: 0;
}

.min-w100 {
  min-width: 100px;
}

.min-w80 {
  min-width: 80px;
}

.input-box {
  width: 100%;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
}

.dropdown-border-none {
  width: 100%;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  background-color: white;
}
.minw-200p {
  min-width: 200px;
}

.min-w150 {
  min-width: 150px;
  display: block;
}

.min-w170 {
  min-width: 170px;
  display: block;
}

.minw-250 {
  min-width: 250px;
}

.search-result-container {
  max-height: calc(100% - 42px);
  overflow: auto;
}
