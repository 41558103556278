#retailer-setting .settings-showcase {
  height: calc(100% - 50px);
  overflow-y: scroll;
  background-color: #ffffff;
  position: absolute;
}

.delivery-switch {
  width: 50%;
  margin-left: auto;
  height: 50px;
  position: fixed;
  top: 50px;
  right: 0;
  background: white;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  z-index: 100;
}

.settings-header {
  position: fixed;
  top: 67px;
  width: 100%;
  z-index: 2;
  @media screen and (min-width: 992px) {
    left: 20.84%;
    right: 0;
    width: auto;
  }
}

.settings-header.app {
  top: 0px;
}

.setup-input {
  max-width: 100%;
  @media screen and (min-width: 576px) {
    max-width: 320px;
  }
}

.google-map-div {
  min-width: 90%;
  min-height: 360px;
}

.map-div-override {
  position: absolute !important;
  min-width: 90% !important;
  min-height: 360px;
  z-index: 2;
}

.setup-input-2 {
  max-width: 100%;
  @media screen and (min-width: 576px) {
    max-width: 157px;
  }
}

.settings-nav {
  position: sticky;
  z-index: 1020;
  top: 0px;
}

.account-details-modal {
  // max-height: 400px;
  overflow-y: scroll;
}

.rc-time-picker-input {
  border: none!important;
  border-radius: 0!important;
  font-size: 16px!important;
  border-bottom: 2px solid #2b2ba0!important;
  color: #2b2ba0!important;
  font-weight: 600!important;
  width: 90px!important;
}

.rc-time-picker-input[disabled] {
  border: none;
  background-color: #FFFFFF!important;
  color: #333333!important;
  border-bottom: 2px solid #cccccc !important;
}

.rc-time-picker-panel-input {
  font-size: 16px;
  color: #2b2ba0;
  font-weight: 600;
}

.checkbox {
  width: 18px;
  height: 18px;
  padding: 0 0.12rem;
}

.checkbox.checked {
  background: url('../../icon/checkbox-check.svg') no-repeat center;
  background-color: #2b2ba0;
  background-origin: content-box;
}

.w-50p {
  width: 50px;
}

.border-bottom-primary {
  border-bottom: 2px solid #2b2ba0 !important;
}


.rounded-button {
  border-radius: 50% !important;
  height: 1.5rem;
  width: 1.5rem;
}

.edit-business-timing-container {
  position: relative;
  min-height: 100vh;
}

.time-picker-container {
  border: 1px solid #dee2e6;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 0px;  
}

.plus-icon {
    font-size: 15px!important;
    font-weight: 700!important;
    color: #2b2ba0!important;
}

.plus-icon.btn:focus {
  box-shadow: none!important;
}

.edit-icon.btn:focus{
  box-shadow: none!important;
}

.custom-checkbox .form-check-input {
  width: 15px;
  height: 21px;
}

.custom-checkbox .form-check-label {
  font-size: 16px;
  font-weight: 400;
}
.business-table tr td{
  border: none;
  font-size: 15px;
}
.sticky-buttons {
  position: sticky;
  bottom: 0;
  background-color: #f8f9fa;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
