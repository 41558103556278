.right-border {
  border-right: 2px solid black;
}

.border-black {
  border: 2px solid black;
}

.minw-200p {
  min-width: 200px;
}

.minh-640p {
  min-height: 640px;
  max-height: 640px;
  overflow-y: scroll;
  @media screen and (max-width: 450px) {
    min-height: 380px;
    max-height: 380px !important;
  }
}

.minh-800p {
  min-height: 80px;
  max-height: 800px;
  overflow-y: scroll;
}

.minh-775p {
  min-height: 775px;
  max-height: 775px;
  overflow-y: scroll;
}

.minh-820p {
  min-height: 80px;
  max-height: 820px;
  overflow-y: scroll;
  overflow-x: hidden;
}