.autosize-modal .modal-dialog {
    height: auto;
}

.autosize-modal .modal-content {
   border-radius: 0;
   border: none;
}

.scrollbar-color::-webkit-scrollbar-thumb {
   background: #c0c0c0;
}

.modal-border .modal-content {
   border: 2px solid !important;
   border-radius: 2px !important;
}