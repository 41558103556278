.side-overlay {
 height: calc(100% - 50px);
 position: absolute;
 overflow-y: scroll;
 bottom: 0;
 z-index: 10002;
 left: 0;
 overflow-x: hidden;
 transition: all 0.5s ease-out;
 -webkit-transition: all 0.5s ease-out;
 -moz-transition: all 0.5s ease-out;
 -o-transition: all 0.5s ease-out;
 -ms-transition: all 0.5s ease-out;
}

 .hide-sideoverlay {
  left: -100vw;
}