.leadership-board {
  border-radius: 16px;
}

.order-button {
  border-radius: 20px;
}

.emp-image {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
}

.bg-pink-light {
  background-color: rgba(241, 224, 255, 0.3);
}

.number-count {
  position: absolute;
}

.red-background {
  background-color: #FFE0E0;
}

.yellow-background {
  background-color: #FFEEC2;
}

.green-background {
  background-color: #B8FFBB;
}

.red-text {
  color: #B82323;
}

.yellow-text {
  color: #A47C17;
}

.green-text {
  color: #4B9557;
}

.border-bottom {
  border: 1px solid #E5E5E5!important;
}

.leader-container {
  max-height: 670px;
  overflow: scroll !important;
}

.main-container {
  border: 1px solid #E5E5E5;
  border-radius: 16px;
}

.text-mid-gray {
  color: #414E5A!important;
}

.border-radius-8 {
  border-radius: 8px!important;
}