@import '../bootstrap.scss';

#custom-table .table-pagination {
  position: fixed;
  bottom: 0;
  box-shadow: 1px 0 4px $secondary;
}

#custom-table .formcontrol-container 
.form-control {
  width: auto !important;
}

.table-sm th {
    padding: 0.6rem 0.75rem !important;
}

.table-sm td {
  padding: 0.4rem 0.75rem !important;
  color: $medium !important;
}

.table th,
.table td {
  white-space: wrap;
  min-width: 85px;
}

.position-sticky {
  position: sticky !important;
}

.overflow-x-scroll {
  overflow-x: scroll;
 }

 ::-webkit-scrollbar:horizontal {
  height: 20px;
}

// .table-fixed thead {
//   display: contents;
// }

// .table-fixed tbody {
//   display: contents;
//   height: 300px;
//   overflow-y: scroll;
// }

// .table-fixed thead tr th
// .table-fixed tbody tr td {
//   float: left;
//   position: relative;
//     &::after {
//       content: '';
//       clear: right;
//     }
// }

// .table-fixed tbody td,
// .table-fixed tbody th,
// .table-fixed thead > tr > th {
//     float: left;
//     position: relative;

//     &::after {
//         content: '';
//         clear: both;
//         display: block;
//     }
// }
//---
// .fixTableHead {
//   overflow-y: auto;
//   overflow-x: auto;
//   height: 1110px;
//   position: relative;
// }
// .fixTableHead thead {
//   position: sticky;
//   top: 30;
//   z-index: 1;
//   background-color: white;
// }
// .fixTableHead tbody {
//   position: relative;
// }
// table {
//   border-collapse: collapse;        
//   width: 100%;
// }

.p {
  position: sticky;
}
