.card-preview {
  position: relative;
  width: 316px;
}

.center-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-one {
  position: absolute;
  top: 0px;
  padding: 15px;
}

.header-two {
  position: absolute;
  top: 25px;
  padding: 25px;
}

.footer-text {
  position: absolute;
  bottom: 70px;
  background-color: yellow;
}

.footer {
  position: absolute;
  bottom: 0px;
}

.logo {
  position: absolute;
  bottom: 0px;
  padding: 10px;
}

.shopName {
  position: absolute;
  bottom: 20px;
  left: 45px;
  padding: 10px;
}

.shopAddress {
  position: absolute;
  bottom: 0px;
  left: 45px;
  padding: 10px;
}

.googleIcon {
  position: absolute;
  bottom: 0px;
  left: 190px;
  padding: 10px;
}
.text-pink {
  color: #b8238e;
}

.fontSize-8 {
  font-size: 8px;
}

.fontSize-9 {
  font-size: 9px;
}

.fontSize-10 {
  font-size: 10px;
}

.fontSize-12 {
  font-size: 12px;
}

.fontSize-14 {
  font-size: 14px;
}

.fontSize-16 {
  font-size: 16px;
}

.fontSize-20 {
  font-size: 20px;
}

.fontWeight-bold {
  font-weight: bold;
}

.fontWeight-italic {
  font-style: italic;
}

.fontWeight-underline {
  text-decoration: underline;
}

.fontFamily-serif {
  font-family: "Times New Roman", Times, serif,
}

.fontFamily-sans-serif {
  font-family: Arial, Helvetica, sans-serif,
}

.fontFamily-monospace {
  font-family: Monospace,
}

.fontFamily-courier_new {
  font-family: "Courier New", sans-serif,
}

.fontFamily-freemono {
  font-family: FreeMono, sans-serif,
}

.banner-container {
  max-height: calc(100% - 200px);
}