@import '../bootstrap.scss';

#retailer-figures {
    height: 100%;
    background: $white;
    margin: 0;
}

#retailer-figures .header {
    height: 50px;
}

#retailer-figures .content {
    overflow-y: auto;
    height: calc(100% - 50px); 
}

#retailer-details .nav-tab {
    display: flex;
    overflow-x: auto;
    border: 0;
    position: absolute;
    width: 100%;
    @media screen and (min-width: 768px) {
         flex-direction: column;
         border: 1px solid #dee2e6;
         position: relative;
         width: auto;
    }
}

#retailer-details .nav-tab .btn {
    white-space: nowrap;
    @media screen and (max-width : 768px) {
      border: 1px solid #dee2e6;
      margin: 1px;
    }
}