.search-customer-list{
    z-index: 1;
    top: 65px;
    max-height: 20rem;
    overflow: auto;
}

.address-list{
    cursor: pointer;
    border: 5px solid #F8F9FA;
}

.location-search .autocomplete-dropdown-container::-webkit-scrollbar
{
  width: 4px;
  height: 0px;
}

.location-search .autocomplete-dropdown-container {
    z-index: 1;
    max-height: 300px!important;
    box-shadow: $box-shadow;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  
  .location-search .suggestion-item {
    background-color: $white;
    cursor: 'pointer';
    padding: 0.5rem;
    border-bottom: 1px solid $disabled;
  }
  
  .location-search .suggestion-item.active {
    background-color: $light;
    cursor: 'pointer';
    padding: 0.5rem;
    font-weight: bold;
  }
  
  .location-search .loading {
    background-color: $white;
    padding: 1rem;
    font-size: 1.2rem;
  }
  
  .location-search {
    height: 400px;
  }
