#order-details .product-image-div{
    max-width: 100px !important;
    max-height: 100px !important;
    @media screen and (max-width: 768px) {
        max-width: 50px !important;
        max-height: 50px !important;
    }
}

.headers {
    background-color: #2b2ba0;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.button-in-header {
    background-color: white !important;
    color: #2b2ba0 !important;
    font-weight: bold !important;
}

.custom-product-tab {
    background-color: #fffcd6;
    color: black;
}

.product-section {
    max-height: 500px;
    overflow: scroll;
    background-color: white;
    margin-bottom: 8px;
}

//  audio palyer css 
.rhap_button-clear.rhap_main-controls-button.rhap_button-prev,
.rhap_button-clear.rhap_main-controls-button.rhap_button-next,
.rhap_button-clear.rhap_volume-button,
.rhap_volume-container,
.rhap_volume-bar,
.rhap_additional-controls,
.rhap_total-time,
.rhap_additional-controls button {
  display: none !important;
}

.rhap_main-controls {
  justify-content: flex-start !important;
  align-items: flex-start !important;

  button:nth-child(1),
  button:nth-child(3) {
    display: none;
  }
}

.audio-player-container {
  display: flex;
  align-items: center;
}

.rhap_main {
  position: relative;
}

.rhap_current-time {
  position: absolute;
  bottom: 12px;
  left: 40px;
}

.rhap_play-pause-button {
  display: flex;
  align-items: center;
  margin-left: 13px !important;
}

.rhap_controls-section {
  margin-top: 0 !important;
}

.rhap_container {
  background-color: #f0f0f0 !important;
  padding: 12px 4px 0px 4px !important;
  border-radius: 4px;
}

.rhap_progress-indicator {
  display: none;
}

.rhap_progress-container {
  height: 0 !important;
  margin-top: 20px;
}

.rhap_progress-bar {
  height: 6px !important;
  border-radius: 4px !important;
}

.rhap_progress-filled {
  border-radius: 4px !important;
}

  
